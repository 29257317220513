export const planDescriptions = {
  freePlan: {
    name: "Free",
    slug: "free",
  },
  goldPlan: {
    name: "Gold",
    slug: "gold",
  },
  platinumPlan: {
    name: "Platinum",
    slug: "platinum",
  },
  diamondPlan: {
    name: "Diamond",
    slug: "diamond",
  },
  goldPlanYearly: {
    name: "1 year of Gold",
    slug: "goldYearly",
  },
  platinumPlanYearly: {
    name: "1 year of Platinum",
    slug: "platinumYearly",
  },
  diamondPlanYearly: {
    name: "1 year of Diamond",
    slug: "diamondYearly",
  },
};

export const productDescriptions = {
  "100 credits": {
    name: "100 Credits",
    slug: "100 credits",
  },
};

export const PLANS = {
  freePlan: {
    rank: 1,
    name: planDescriptions.freePlan.name,
    slug: planDescriptions.freePlan.slug,
    quota: 0,
    submissions: 0,
    price: {
      amount: 0,
      priceId: "",
    },
    billing: "monthly" as "monthly",
  },
  goldPlan: {
    rank: 2,
    name: planDescriptions.goldPlan.name,
    slug: planDescriptions.goldPlan.slug,
    quota: 50,
    submissions: 50,
    price: {
      amount: 4.99,
      priceId: process.env.PRICE_ID_GOLD_PLAN,
    },
    billing: "monthly" as "monthly",
  },
  platinumPlan: {
    rank: 3,
    name: planDescriptions.platinumPlan.name,
    slug: planDescriptions.platinumPlan.slug,
    quota: 75,
    submissions: 75,
    price: {
      amount: 7.99,
      priceId: process.env.PRICE_ID_PLATINUM_PLAN,
    },
    billing: "monthly" as "monthly",
  },
  diamondPlan: {
    rank: 4,
    name: planDescriptions.diamondPlan.name,
    slug: planDescriptions.diamondPlan.slug,
    quota: 100,
    submissions: 100,
    price: {
      amount: 9.99,
      priceId: process.env.PRICE_ID_DIAMOND_PLAN,
    },
    billing: "monthly" as "monthly",
  },
  goldPlanYearly: {
    rank: 1,
    name: planDescriptions.goldPlanYearly.name,
    slug: planDescriptions.goldPlanYearly.slug,
    quota: 50,
    submissions: 50,
    price: {
      amount: 49.99,
      priceId: process.env.PRICE_ID_GOLD_YEARLY_PLAN,
    },
    billing: "yearly" as "yearly",
  },
  platinumPlanYearly: {
    rank: 2,
    name: planDescriptions.platinumPlanYearly.name,
    slug: planDescriptions.platinumPlanYearly.slug,
    quota: 75,
    submissions: 75,
    price: {
      amount: 74.99,
      priceId: process.env.PRICE_ID_PLATINUM_YEARLY_PLAN,
    },
    billing: "yearly" as "yearly",
  },
  diamondPlanYearly: {
    rank: 3,
    name: planDescriptions.diamondPlanYearly.name,
    slug: planDescriptions.diamondPlanYearly.slug,
    quota: 100,
    submissions: 0,
    price: {
      amount: 99.99,
      priceId: process.env.PRICE_ID_DIAMOND_YEARLY_PLAN,
    },
    billing: "yearly" as "yearly",
  },
};

export const PRODUCTS = {
  "100 credits": {
    name: productDescriptions["100 credits"].name,
    slug: productDescriptions["100 credits"].slug,
    quota: 100,
    submissions: 100,
    price: {
      amount: 9.99,
      priceId: process.env.PRICE_ID_OTP,
    },
  },
};

export type ProductType = (typeof PRODUCTS)[keyof typeof PRODUCTS];
export type PlanType = (typeof PLANS)[keyof typeof PLANS];

const example = {
  id: "price_1PE64FEki3HAZ0EZB9UHK1wg",
  object: "price",
  active: true,
  billing_scheme: "per_unit",
  created: 1715157699,
  currency: "usd",
  custom_unit_amount: null,
  livemode: false,
  lookup_key: null,
  metadata: {},
  nickname: null,
  product: "prod_Q4EXDBL8rcy0lH",
  recurring: null,
  tax_behavior: "unspecified",
  tiers_mode: null,
  transform_quantity: null,
  type: "one_time",
  unit_amount: 999,
  unit_amount_decimal: "999",
};

import clsx from "clsx";
import { pricingItems } from "../utils/utilts";
import { PLANS } from "@/config/plans";
import UpgradeButton from "./UpgradeButton";
import PriceCard from "./PriceCard";
import { PlanCardType } from "../types/types";

const getOuterContainerStyle = (
  object:
    | { isViewOnly: boolean; isSubscribedToProPlan?: never }
    | { isViewOnly?: never; isSubscribedToProPlan: boolean },
) => {
  const { isViewOnly, isSubscribedToProPlan } = object;

  const base =
    "flex flex-col relative border-opacity-25 shadow-black/25 shadow-md bg-gradient-to-r from-yellow-500 to-yellow-300 bg-opacity-75";

  if (isViewOnly) return base;

  const outerContainerStyle = clsx(
    base,
    !isSubscribedToProPlan && "border-2 border-violet-600",
    isSubscribedToProPlan && "border-2 border-green-600",
  );
  return outerContainerStyle;
};

const GoldPlanCard = ({
  baseWrapperForPlanBannerStyle,
  subscriptionPlan,
  billing,
}: PlanCardType) => {
  const isSubscribedToProPlan =
    (billing === "monthly" && subscriptionPlan.planType === "goldPlan") ||
    (billing === "yearly" && subscriptionPlan.planType === "goldPlanYearly");

  const isUpgradeOrDowngrade =
    subscriptionPlan.isSubscribed && !isSubscribedToProPlan;
  const isFreeTrial = subscriptionPlan.stripeSubscriptionStatus === "trialing";

  const outerContainerStyle = getOuterContainerStyle({ isSubscribedToProPlan });
  const wrapperForPlanBannerStyle = clsx(
    baseWrapperForPlanBannerStyle,
    isUpgradeOrDowngrade && "hidden",
    isSubscribedToProPlan
      ? "from-green-400 to-green-500  px-3 py-2 text-sm font-medium text-white"
      : "from-yellow-600 to-yellow-400 px-3 py-2 text-sm font-medium text-white",
  );

  // const proPricingItem = pricingItems.find((item) => item.plan === planDescriptions.diamondPlan.name)

  let goldPricingItem = pricingItems.goldPlan;
  if (billing === "yearly") goldPricingItem = pricingItems.goldPlanYearly;

  //person is either currently trialing, or has never subscribed.
  //it should show that the pro plan is free for 1 month.
  //otherwise, show that it costs $x.xx per month.
  const shouldShowTrialingDetails =
    subscriptionPlan.stripeSubscriptionStatus === "trialing" ||
    !subscriptionPlan.hasSubscribedAtLeastOnce;
  const freeTrialAvailable = !subscriptionPlan.hasSubscribedAtLeastOnce;
  if (!goldPricingItem) return null;

  const renderPriceDisplay = () => {
    const goldPlanDetails =
      billing === "monthly" ? PLANS.goldPlan : PLANS.goldPlanYearly;
    const [upperTextNoTrial, lowerTextNoTrial] = [
      `$${goldPlanDetails!.price.amount}`,
      billing === "yearly" ? "per year" : "per month",
    ];

    const [upperTextForFreeTrial, lowerTextForFreeTrial] = [
      `Free for 1 month`,
      `then $${goldPlanDetails!.price.amount} ${
        billing === "yearly" ? "/year" : "/month"
      }`,
    ];

    const [upperText, lowerText] = shouldShowTrialingDetails
      ? [upperTextForFreeTrial, lowerTextForFreeTrial]
      : [upperTextNoTrial, lowerTextNoTrial];

    return (
      <>
        <p className="mt-3 lg:mt-5 font-display text-2xl lg:text-4xl font-semibold">
          {upperText}
        </p>
        <p className="text-white-500">{lowerText}</p>
      </>
    );
  };

  const isDisabledUpgradeButton =
    isUpgradeOrDowngrade && subscriptionPlan.billing !== billing;

  let activeBilling: "monthly" | "yearly" | false = false;

  if (subscriptionPlan.billing === "monthly") activeBilling = "monthly";
  else if (subscriptionPlan.billing === "yearly") activeBilling = "yearly";

  const renderFooter = () => {
    return (
      <UpgradeButton
        activeBilling={activeBilling}
        isDisabled={isDisabledUpgradeButton}
        isSubscribedToProPlan={isSubscribedToProPlan}
        freeTrialAvailable={freeTrialAvailable}
        planType="goldPlan"
        yearlyPlanType="goldPlanYearly"
        buttonStyle="bg-yellow-600 hover:bg-yellow-700"
        billing={billing}
      />
    );
  };

  const renderCancellationNotice = () => {
    return (
      <>
        {" "}
        {subscriptionPlan.isCanceled &&
          subscriptionPlan.stripeCurrentPeriodEnd &&
          isSubscribedToProPlan && (
            <div className="w-full absolute shadow-lg top-[100%] bg-black text-white rounded-b-lg">
              <p className="text-center w-full">
                Cancels at{" "}
                {subscriptionPlan.stripeCurrentPeriodEnd.toDateString()}
              </p>
            </div>
          )}
      </>
    );
  };

  return (
    <PriceCard
      outerContainerStyle={outerContainerStyle}
      wrapperForPlanBannerStyle={wrapperForPlanBannerStyle}
      wrapperForPlanBannerText={
        isSubscribedToProPlan ? "Current plan" : "Upgrade"
      }
      planTitle={goldPricingItem.plan}
      tagline={goldPricingItem.tagline}
      priceDisplay={renderPriceDisplay()}
      cardMiddleZone={
        <>{goldPricingItem.quota.toLocaleString()} submissions/month</>
      }
      features={goldPricingItem.features}
      footer={renderFooter()}
      cancellationNotice={renderCancellationNotice()}
    />
  );
};

const GoldPlanViewOnly = () => {
  let goldPricingItem = pricingItems.goldPlan!;
  const outerContainerStyle = getOuterContainerStyle({ isViewOnly: true });
  return (
    <PriceCard
      outerContainerStyle={outerContainerStyle}
      wrapperForPlanBannerStyle={"hidden"}
      wrapperForPlanBannerText=""
      planTitle={goldPricingItem.plan}
      tagline={goldPricingItem.tagline}
      priceDisplay={<></>}
      cardMiddleZone={
        <>{goldPricingItem.quota.toLocaleString()} submissions/month</>
      }
      features={goldPricingItem.features}
      footer={<></>}
      cancellationNotice={<></>}
    />
  );
};

export { GoldPlanCard, GoldPlanViewOnly };

// import { TooltipContent, Tooltip, TooltipTrigger } fr
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import clsx from "clsx";
import { Check, HelpCircle, Minus } from "lucide-react";

type PriceCardProps = {
  outerContainerStyle: string;
  wrapperForPlanBannerStyle?: string;
  wrapperForPlanBannerText?: string;
  shouldShowBanner?: boolean;
  planTitle: string;
  tagline: string;
  priceDisplay: React.ReactNode;
  cardMiddleZone: React.ReactNode;
  tooltipText?: string;
  features: { text: string; footnote?: string; negative?: boolean }[];
  footer: React.ReactNode;
  reverseTextColor?: boolean;
  cancellationNotice?: React.ReactNode;
};

const PriceCard = ({
  outerContainerStyle,
  wrapperForPlanBannerStyle,
  wrapperForPlanBannerText,
  planTitle,
  tagline,
  priceDisplay,
  cardMiddleZone,
  tooltipText,
  features,
  footer,
  shouldShowBanner = true,
  reverseTextColor = false,
  cancellationNotice,
}: PriceCardProps) => {
  const renderFeatures = () => {
    return (
      <ul className="my-2 space-y-5 px-8 flex-wrap">
        {features.map(({ text, footnote, negative }) => (
          <li key={text} className="flex break-all space-x-5">
            <div className="flex-shrink-0">
              {negative ? (
                <Minus className="h-6 w-6 text-gray-300" />
              ) : (
                <Check
                  className={clsx(
                    "h-6 w-6 text-blue-600",
                    reverseTextColor && "!text-blue-200",
                  )}
                />
              )}
            </div>
            {footnote ? (
              <div className="flex w-full justify-between items-center">
                <p
                  className={clsx("text-gray-800 text-start", {
                    "!text-gray-300": negative,
                    "!text-slate-100": reverseTextColor && !negative,
                  })}
                >
                  {text}
                </p>
                <Tooltip delayDuration={300}>
                  <TooltipTrigger className="cursor-default ml-1.5">
                    <HelpCircle
                      className={clsx("h-4 w-4", {
                        "text-white": reverseTextColor,
                        "text-zinc-500": !reverseTextColor,
                      })}
                    />
                  </TooltipTrigger>
                  <TooltipContent className="w-80 p-2">
                    {footnote}
                  </TooltipContent>
                </Tooltip>
              </div>
            ) : (
              <p
                className={clsx("text-gray-600", {
                  "text-gray-400": negative,
                  "text-white": reverseTextColor && !negative,
                })}
              >
                {text}
              </p>
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      className={clsx(
        outerContainerStyle,
        "w-full min-w-[20rem] justify-self-center flex-shrink",
      )}
    >
      {shouldShowBanner && (
        <div className={wrapperForPlanBannerStyle}>
          {wrapperForPlanBannerText}
        </div>
      )}

      <div className="p-5">
        <h3
          className={clsx(
            "my-3 text-center justify-self-center flex justify-center w-full font-display text-xl sm:text-3xl font-bold",
            reverseTextColor && "text-white",
          )}
        >
          {planTitle}
        </h3>
        <div
          className={clsx(
            "text-gray-600 min-h-[3rem]",
            reverseTextColor && "!text-slate-100",
          )}
        >
          {tagline}
        </div>

        <div className={clsx("min-h-[3rem]", reverseTextColor && "text-white")}>
          {priceDisplay}
        </div>
      </div>

      <div className={`flex mt-2 items-center font-semibold`}>
        <div className="flex w-full items-center justify-center space-x-1 mb-4">
          <p
            className={clsx(
              "flex justify-center",
              reverseTextColor && "text-white",
            )}
          >
            {cardMiddleZone}
          </p>

          <Tooltip delayDuration={300}>
            <TooltipTrigger className="cursor-default ml-1.5">
              <HelpCircle
                className={clsx(
                  "h-4 w-4",
                  reverseTextColor ? "text-white" : "text-zinc-500",
                )}
              />
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent className="w-80 p-2">
                {tooltipText
                  ? tooltipText
                  : "How many submissions you can upload per month."}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </div>
      </div>
      {renderFeatures()}
      <div className="flex-grow" />
      <div className="mt-auto flex w-full flex-col items-end p-5">{footer}</div>
      {cancellationNotice}
    </div>
  );
};

export default PriceCard;

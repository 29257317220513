import {
  planDescriptions,
  PLANS,
  productDescriptions,
  PRODUCTS,
} from "@/config/plans";

const PRODUCTS_AND_PLANS = { ...PLANS, ...PRODUCTS };

//enforce some kind of consistency in stripe-related objects throughout files
type pricingItemsType = {
  [typesOfPlansAndProducts in keyof typeof PRODUCTS_AND_PLANS]?: {
    plan: string;
    tagline: string;
    subscribe: boolean;
    quota: number;
    features: { text: string; footnote: string }[];
  };
};

export const pricingItems: pricingItemsType = {
  freePlan: {
    plan: planDescriptions.freePlan.name,
    tagline: "For users who just want to try out our features.",
    subscribe: true,
    quota: 0,
    features: [
      {
        text: `0 free submissions/month`,
        footnote: "Resets every month.",
      },
      {
        text: "No other benefits. What did you expect?",
        footnote: "The maximum file size of a single image file.",
      },
    ],
  },
  diamondPlan: {
    plan: planDescriptions.diamondPlan.name,
    tagline: "For users who want a LOT of Chess scoresheet automation.",
    subscribe: true,
    quota: PLANS.diamondPlan.quota,
    features: [
      {
        text: "100 submissions / month that expire if not used",
        footnote: "Resets every month.",
      },
      {
        text: "Earns our respect",
        footnote: "The maximum file size of a single image file.",
      },
    ],
  },
  diamondPlanYearly: {
    plan: planDescriptions.diamondPlanYearly.name,
    tagline:
      "Wow. You really want a lot of Chess scoresheet automation, don't you?",
    subscribe: true,
    quota: PLANS.diamondPlanYearly.quota,
    features: [
      {
        text: "100 submissions / month that expire if not used",
        footnote: "Resets every month.",
      },
      {
        text: "Earns our respect",
        footnote: "The maximum file size of a single image file.",
      },
    ],
  },
  goldPlan: {
    plan: planDescriptions.goldPlan.name,
    tagline: "For users who want a golden experience.",
    subscribe: true,
    quota: PLANS.goldPlan.quota,
    features: [
      {
        text: "50 submissions / month that expire if not used",
        footnote: "Resets every month.",
      },
      {
        text: "Earns our respect",
        footnote: "The maximum file size of a single image file.",
      },
    ],
  },
  goldPlanYearly: {
    plan: planDescriptions.goldPlanYearly.name,
    tagline: "Gold! Wait, you're not a miner. Why do you need this?",
    subscribe: true,
    quota: PLANS.goldPlanYearly.quota,
    features: [
      {
        text: "50 submissions / month that expire if not used",
        footnote: "Resets every month.",
      },
      {
        text: "Earns our respect",
        footnote: "The maximum file size of a single image file.",
      },
    ],
  },
  platinumPlan: {
    plan: planDescriptions.platinumPlan.name,
    tagline: "Pristine number of submissions.",
    subscribe: true,
    quota: PLANS.platinumPlan.quota,
    features: [
      {
        text: "75 submissions / month that expire if not used",
        footnote: "Resets every month.",
      },
      {
        text: "Earns our respect",
        footnote: "The maximum file size of a single image file.",
      },
    ],
  },
  platinumPlanYearly: {
    plan: planDescriptions.platinumPlanYearly.name,
    tagline: "Pristine number of submissions.",
    subscribe: true,
    // quota: PLANS.find((p) => p.slug === planDescriptions.proPlan.slug)!.quota,
    quota: PLANS.platinumPlanYearly.quota,
    features: [
      {
        text: "75 submissions / month that expire if not used",
        footnote: "Resets every month.",
      },
      {
        text: "Earns our respect",
        footnote: "The maximum file size of a single image file.",
      },
    ],
  },
  "100 credits": {
    plan: productDescriptions["100 credits"].name,
    subscribe: false,
    tagline: "Purchase 100 credits.",
    // quota: PRODUCTS.find((p) => p.slug === "100 credits")!.quota,
    quota: PRODUCTS["100 credits"].quota,
    features: [
      {
        text: "100 submissions that do not expire",
        footnote: "One time only.",
      },
      {
        text: "You are about to have hella currency",
        footnote: "The maximum file size of a single image file.",
      },
    ],
  },
};

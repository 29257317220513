"use client";

import { ArrowRight } from "lucide-react";
import { Button } from "@/components/ui";
import { trpc } from "@/app/_trpc/client";
import { PLANS, PRODUCTS } from "@/config/plans";
import clsx from "clsx";

const UpgradeButton = ({
  isSubscribedToProPlan = false,
  freeTrialAvailable,
  planType,
  yearlyPlanType,
  buttonStyle,
  isDisabled = false,
  billing,
  activeBilling,
}: {
  activeBilling: "monthly" | "yearly" | false;
  isSubscribedToProPlan: boolean;
  freeTrialAvailable: boolean;
  planType: keyof typeof PLANS;
  yearlyPlanType: keyof typeof PLANS;
  buttonStyle?: string;
  isDisabled?: boolean;
  billing: "monthly" | "yearly";
}) => {
  const { mutate: createStripeSession } = trpc.createStripeSession.useMutation({
    onSuccess: ({ url }) => {
      window.location.href = url ?? "/dashboard/billing";
    },
  });

  let textToShowIfProPlanIsNotActivated;
  if (freeTrialAvailable)
    textToShowIfProPlanIsNotActivated = "Start free trial";
  else if (isDisabled) {
    if (activeBilling === "monthly")
      textToShowIfProPlanIsNotActivated = "Change next month";
    else if (activeBilling === "yearly")
      textToShowIfProPlanIsNotActivated = "Change next year";
  } else textToShowIfProPlanIsNotActivated = "Upgrade now";

  const planTypeToPass = billing === "monthly" ? planType : yearlyPlanType;

  return (
    <Button
      disabled={isDisabled}
      onClick={() => createStripeSession({ planType: planTypeToPass })}
      className={clsx(
        "w-full transition-colors",
        buttonStyle ? buttonStyle : "bg-blue-600 hover:bg-blue-800",
      )}
    >
      {isSubscribedToProPlan
        ? "Manage subscription"
        : textToShowIfProPlanIsNotActivated}{" "}
      <ArrowRight className="h-5 w-5 ml-1.5" />
    </Button>
  );
};

export const CreditsButton = ({
  productType,
}: {
  productType: keyof typeof PRODUCTS;
}) => {
  const { mutate: createStripeSession } = trpc.createStripeSession.useMutation({
    onSuccess: ({ url }) => {
      window.location.href = url ?? "/dashboard/billing";
    },
  });

  return (
    <Button
      onClick={() => createStripeSession({ productType: productType })}
      className="w-full"
    >
      Buy now <ArrowRight className="h-5 w-5 ml-1.5" />
    </Button>
  );
};

export default UpgradeButton;
